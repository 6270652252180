<template>
  <div>
    <div class="background">
      <Navbar page="info-providers" />
      <div
        class="header-content d-flex align-center pb-4 pb-sm-16 px-5 px-sm-10 px-md-0 white--text"
      >
        <v-row no-gutters justify="center">
          <v-col cols="12" md="7" class="px-6 px-md-12 pb-10 pb-md-0">
            <p class="text-h1 font-weight-bold text-center">
              Se parte de la comunidad <br />más grande de proveedores
            </p>
            <p class="text-h4 font-weight-regular text-center">
              Haz crecer tu negocio con Zaturna, descubre los beneficios de
              pertenecer a nuestra plataforma y aumenta el éxito de tu negocio.
            </p>
            <div class="d-flex justify-center">
              <Button
                text="Unirme ahora"
                aria-label="Abrir ventana de registro de usuario y proveedor"
                @event="register()"
              />
            </div>
            <p class="mt-6 text-h4 font-weight-regular text-center">
              ¿Ya tienes cuenta?
              {{ " " }}

              <v-btn
                depressed
                text
                dark
                :ripple="false"
                class="btn-background-transparent v-btn--active pa-0"
                @click="login"
              >
                <span
                  class="text-none text-h4 font-weight-medium text-decoration-underline white-text"
                >
                  Inicia sesión aquí
                </span>
              </v-btn>
            </p>
          </v-col>
          <!-- <v-col cols="12" md="7">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-center justify-md-end"
                >
                  <v-card max-width="300" outlined color="transparent">
                    <v-img src="@/assets/img/centro_mesa_1.jpg"></v-img>

                    <v-card-title class="black--text">
                      <div class="text-h5 font-weight-bold">
                        {{ service.name }}
                      </div>
                    </v-card-title>

                    <v-card-text class="black--text">
                      <div class="text-h6">
                        {{ service.category }}
                      </div>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                        text
                        class="text-decoration-underline text-body-1 font-weight-bold"
                        :ripple="false"
                        :to="{ name: 'Services' }"
                      >
                        Ver servicios
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-center justify-md-end"
                >
                  <v-card max-width="300" outlined color="transparent">
                    <v-img src="@/assets/img/centro_mesa_2.jpg"></v-img>

                    <v-card-title class="black--text">
                      <div class="text-h5 font-weight-bold">
                        {{ provider.name }}
                      </div>
                    </v-card-title>

                    <v-card-text class="black--text">
                      <div class="text-h6">
                        {{ provider.category }}
                      </div>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                        text
                        class="text-decoration-underline text-body-1 font-weight-bold"
                        :ripple="false"
                        :to="{
                          name: 'Providers',
                        }"
                      >
                        Ver proveedores
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-col> -->
        </v-row>
      </div>
    </div>
    <ModalLoginRegister
      :dialog="dialog"
      :modal-type="modalType"
      :redirect-login="redirectLogin"
      :redirect-register="redirectRegister"
      register-title="Regístrate"
      register-subtitle="Regístrate como cliente Zaturna para después poder crear tu perfil de proveedor."
      @closeModal="handleModal"
      @handleModalType="handleModalType"
    />
  </div>
</template>

<script>
import Navbar from "@/components/LandingPage/Shared/Navbar.vue";
import ModalLoginRegister from "@/components/LandingPage/Shared/Modal/ModalLoginRegister.vue";
import Button from "@/components/Shared/Button.vue";

export default {
  name: "HeaderProvider",
  components: {
    Navbar,
    ModalLoginRegister,
    Button,
  },
  data: () => ({
    // !  Get data from api
    provider: {
      name: "Castalia",
      category: "Salón de eventos",
    },
    service: {
      name: "Mobil Lounge",
      category: "Mobiliario y decoracion",
    },
    dialog: false,
    modalType: "",
    redirectLogin: "ProviderHomepage",
    redirectRegister: "RegisterProvider",
  }),
  methods: {
    register() {
      this.handleModalType("register");
      this.handleModal();
    },
    login() {
      this.handleModalType("login");
      this.handleModal();
    },
    handleModalType(type) {
      this.modalType = type;
    },
    handleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  min-height: 100vh;
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 20%,
      rgba(98, 37, 130, 1) 100%
    ),
    url("../../../assets/img/fondo_proveedor.png") no-repeat center center !important;
  background-size: cover !important;
}

.header-content {
  height: calc(100vh - 88px);
}

.btn-background-transparent::before {
  background-color: transparent !important;
}
</style>
