<template>
  <div>
    <v-container class="mt-12 mb-6 text-center">
      <div class="text-h1">Descubre los beneficios de Zaturna</div>
    </v-container>
    <v-flex d-flex>
      <v-layout wrap>
        <v-flex
          v-for="(benefit, index) in benefits"
          :key="index"
          lg4
          md6
          sm12
          xs12
        >
          <v-card
            outlined
            color="transparent"
            class="mx-auto my-12 rounded-md"
            max-width="350"
          >
            <!-- <v-img :src="benefit.image" class="mb-3"></v-img> -->
            <v-card-title class="black--text">
              <div class="text-h2">{{ benefit.name }}</div>
            </v-card-title>
            <v-card-text class="black--text">
              <div class="text-h4">
                {{ benefit.text }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <!-- <v-flex lg4 md6 sm12 xs12>
          <v-card
            outlined
            color="transparent"
            class="mx-auto my-12"
            max-width="350"
          >
            <v-img
              src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
            ></v-img>

            <v-card-title class="black--text">
              <div class="text-h5 font-weight-bold">¿Por qué usar Zaturna?</div>
            </v-card-title>

            <v-card-text class="black--text">
              <div class="text-subtitle-1">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magni
                quia tempora maiores autem cupiditate obcaecati enim, nemo,
                officia incidunt non mollitia sequi totam similique provident
                nostrum unde rem dolores minima.
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex lg4 md6 sm12 xs12>
          <v-card
            outlined
            color="transparent"
            class="mx-auto my-12"
            max-width="350"
          >
            <v-img
              src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
            ></v-img>

            <v-card-title class="black--text">
              <div class="text-h5 font-weight-bold">¿Por qué usar Zaturna?</div>
            </v-card-title>

            <v-card-text class="black--text">
              <div class="text-subtitle-1">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magni
                quia tempora maiores autem cupiditate obcaecati enim, nemo,
                officia incidunt non mollitia sequi totam similique provident
                nostrum unde rem dolores minima.
              </div>
            </v-card-text>
          </v-card>
        </v-flex> -->
      </v-layout>
    </v-flex>
  </div>
</template>

<script>
export default {
  name: "ZaturnaBenefits",
  data: () => ({
    benefits: [
      {
        name: "Mayor exposición",
        image: "https://cdn.vuetifyjs.com/images/cards/cooking.png",
        text: `
          Más personas interesadas en tus servicios verán tu empresa, teniendo la posibilidad de contratarte directamente en nuestra plataforma.
        `,
      },
      {
        name: "Solicitudes",
        image: "https://cdn.vuetifyjs.com/images/cards/cooking.png",
        text: `
         Ten una comunicación directa con tus prospectos desde tu panel de proveedor.
        `,
      },
      {
        name: "Vende más",
        image: "https://cdn.vuetifyjs.com/images/cards/cooking.png",
        text: `
          Queremos que tu negocio siga creciendo. Aumenta tus ventas perteneciendo a la red de proveedores de toda clase de eventos más grande.
        `,
      },
    ],
  }),
};
</script>
