<template>
  <div>
    <HeaderProvider />
    <ZaturnaBenefits />
    <div class="grey lighten-4">
      <div class="py-16">
        <ListCategories
          :zaturna-inspiration="false"
          title="Ofrece tus servicios para cualquier tipo de evento"
        />
      </div>
    </div>
    <ZaturnaSecurity />
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/LandingPage/Shared/Footer.vue";
import ListCategories from "../../components/LandingPage/MainPage/ListCategories.vue";
import ZaturnaBenefits from "../../components/LandingPage/MainPageProviders/ZaturnaBenefits.vue";
import HeaderProvider from "../../components/LandingPage/MainPageProviders/HeaderProvider.vue";
import ZaturnaSecurity from "../../components/LandingPage/MainPageProviders/ZaturnaSecurity.vue";

export default {
  name: "MainPageProvider",
  components: {
    Footer,
    ListCategories,
    ZaturnaBenefits,
    HeaderProvider,
    ZaturnaSecurity,
  },
};
</script>
