<template>
  <div class="mb-12">
    <v-container class="mt-12 mb-6 text-center">
      <div class="text-h1">Seguridad para todos nuestros proveedores</div>
    </v-container>
    <div>
      <v-container v-for="(rule, index) in rules" :key="index">
        <v-row no-gutters align="center">
          <v-col cols="12" md="12" lg="1"> </v-col>
          <v-col cols="12" md="12" lg="1" class="text-center">
            <img width="75" src="@/assets/icons/CheckmarkCircle.svg" />
          </v-col>
          <v-col cols="12" md="12" lg="3" class="text-center text-lg-left">
            <div class="text-h2 pb-4 pb-lg-0">
              {{ rule.title }}
            </div>
          </v-col>
          <v-col cols="12" md="12" lg="6">
            <v-responsive
              class="text-h4 mx-auto mx-lg-0"
              :max-width="max_width"
            >
              {{ rule.text }}
            </v-responsive>
          </v-col>
          <v-col cols="12" md="12" lg="1"> </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZaturnaSecurity",
  data: () => ({
    rules: [
      {
        title: "Plataforma",
        text: `
        Nuestra plataforma te brindará las herramientas necesarias para ponerte en contacto con tus clientes y brindar un servicio profesional.
        `,
      },
      {
        title: "Asesoría",
        text: `
          El equipo de Zaturna está dispuesto a resolver cualquier duda que presentes.
        `,
      },
      {
        title: "Contratación",
        text: `
        Cada servicio contratado a través de Zaturna cuenta con un contrato que da respaldo legal para cumplir cada aspecto de la contratación.
        `,
      },
    ],
  }),
  computed: {
    max_width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 400;
        case "sm":
          return 500;
        case "md":
          return 650;
        default:
          return 800;
      }
    },
  },
};
</script>
